import {
    SUPPORT_MODAL_CLOSE,
    SUPPORT_MODAL_SHOW,
} from '../actions/actionSupport';

export const supportModal = (state = {
                             isModalOpen: false,
                         },
                         action) => {
        switch (action.type) {
            case SUPPORT_MODAL_SHOW:
                return Object.assign({}, state, { isModalOpen: true });
            case SUPPORT_MODAL_CLOSE: {
                return Object.assign({}, state, { isModalOpen: false });
            }
            default:
                return state;
        }
    }
;
