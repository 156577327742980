import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'searchPage.title.search',
        defaultMessage: 'SEARCH'
    },
    INPUT_VIN: {
        id: 'searchPage.inputVin',
        defaultMessage: 'Input VIN'
    },
    VIN: {
        id: 'searchPage.vin',
        defaultMessage: 'VIN'
    },
    TVV: {
        id: 'searchPage.tvv',
        defaultMessage: 'TVV'
    },
    COLOR: {
        id: 'searchPage.color',
        defaultMessage: 'Color'
    },
    MTW: {
        id: 'searchPage.mtw',
        defaultMessage: 'Max. Total Weight'
    },
    MWFA: {
        id: 'searchPage.mwfa',
        defaultMessage: 'Max. Weight of Front Axle'
    },
    MWBA: {
        id: 'searchPage.mwba',
        defaultMessage: 'Max. Weight of Back Axle'
    },
    MWAV: {
        id: 'searchPage.mwav',
        defaultMessage: 'Max. Weight of Articulated Vehicle'
    },
    AOC_CODE: {
        id: 'searchPage.aocCode',
        defaultMessage: 'AOC Code'
    },
    ZTO_CODE: {
        id: 'searchPage.ztoCode',
        defaultMessage: 'ZTO Code'
    },
    DESCRIPTION: {
        id: 'searchPage.description',
        defaultMessage: 'Description'
    },
    VIN_NOT_FOUND: {
        id: 'searchPage.vinNotFound',
        defaultMessage: 'VIN not found'
    },
    BVM_API_ERROR: {
        id: 'searchPage.bvmAPIError',
        defaultMessage: 'BVM API is unreachable'
    },
    FAILED_TO_FETCH_BE: {
        id: 'searchPage.failedToFetchBe',
        defaultMessage: 'Failed to fetch data from BE'
    },
    LOADING: {
        id: 'searchPage.loading',
        defaultMessage: 'Loading'
    },
});

export default messages;



