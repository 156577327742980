import * as actionTypes from '../actions/search/actionTypes';

const initialState = {
    searchedVehicle: {
        status: '',
        vin: '',
        tvv: '',
        color: '',
        mtw: '',
        mwfa: '',
        mwba: '',
        mwav: '',
        aoczto: '',
    },
    isLoading: false,
};

const searchReducer = (state = initialState, action ) => {
    switch (action.type){
        case actionTypes.SEARCH:
            return { ...state, searchedVehicle: action.searchedVehicle, isLoading: false};
        case actionTypes.LOADING:
            return { ...state, isLoading: action.isLoading};
        default:
            return {...state};
    }
}

export default searchReducer;