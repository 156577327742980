import React from 'react';
import { FormattedMessage } from 'react-intl';

const ValidFormattedMessage = props => {
    const { message, intlMessages } = props;
    if (!intlMessages || !intlMessages[message]) {
        return <span>{message}{props.children}</span>;
    }
    return <><FormattedMessage {...intlMessages[message]}/>{props.children}</>;
};
export default ValidFormattedMessage;



