import axios from 'axios';
import {axiosInstance} from '../../api/rest';
import * as actionTypes from './actionTypes'

export const searchVin = ( vin ) => {
    return (dispatch, getState) => {
        console.log(process.env.REACT_APP_BE_WS_URL);
        axios.get(process.env.REACT_APP_BE_WS_URL+'search?vin='+vin).then(response => response.data)
        .then((data) => {
            dispatch({type: actionTypes.SEARCH, searchedVehicle: data});
            console.log(data)
        })
        .catch(error => {
            dispatch({type: actionTypes.SEARCH, searchedVehicle: {status: 999}});
            console.log(error);
        })
        // axiosInstance.get(process.env.REACT_APP_BE_WS_URL+'search?vin='+vin)
        //     .then(response => response.data)
        //     .then((data) => {
        //         dispatch({type: actionTypes.SEARCH, searchedVehicle: data});
        //         console.log(data)
        //     })
        //     .catch(error => {
        //         dispatch({type: actionTypes.SEARCH, searchedVehicle: {status: 999}});
        //         console.log(error);
        //     })
    }
};

export const loading = ( ) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.LOADING, isLoading: true})
    }
}

export const reload = ( ) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.RELOAD, reloaded: true})
    }
}