import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { required } from './validationCommons';
import { Field } from 'redux-form';
import { RequiredSpan } from './StyledComponents';
import SelectFieldWithValidation, { FieldWithValidation } from './FormField';

const InputSelectFieldWithValidation = (props) => {
    const { style, label, field, options, isDisabled, isRequired, intlMessages, intl, handleChange, noEmpty } = props;
    let validations = [];
    if (isRequired) {
        validations.push(required);
    }
    return (
        <div>
            {!!label && label instanceof Object &&
            <label className="col-form-label pl-0 font-weight-bold">
                <FormattedMessage {...label}/>
                {!isDisabled && isRequired ? <RequiredSpan>{'\u00A0*'}</RequiredSpan> : null}
            </label>
            }
            <Field name={field}
                   component={isDisabled ? FieldWithValidation : SelectFieldWithValidation}
                   type="text"
                   validate={validations}
                   isDisabled={isDisabled}
                   style={style}
                   intlMessages={intlMessages}
                   options={options}
                   format={isDisabled && intlMessages ? ((value) => value ? (intlMessages[value] ? intl.formatMessage(intlMessages[value]) : value) : null) : null}
                   onChange={handleChange ? handleChange : undefined}
                   isRequired
                   label={label}
                   isSmall
                   placeholder="---"
                   noEmpty={noEmpty}/>
        </div>
    )
};
export default (injectIntl(InputSelectFieldWithValidation));
