import React from 'react';
import {Form, Col, Table} from 'react-bootstrap';
import searchPageMessages from "../../../intl/search/searchLabels";
import {FormattedMessage} from "react-intl";

const SearchResultTable = (props) => {
    const { tableData } = props;
    return (
        <Form.Row>
            <Col xs={{span: 7, offset: 2}}>
                <Table striped bordered responsive="sm">
                    <thead>
                    <tr>
                        <th><FormattedMessage {...searchPageMessages.AOC_CODE} /></th>
                        <th><FormattedMessage {...searchPageMessages.ZTO_CODE} /></th>
                        <th><FormattedMessage {...searchPageMessages.DESCRIPTION} /></th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableData.sort((a, b) => a.zto > b.zto ? 1 : -1).map((item) => <tr key={item.zto}><td>{item.aoc}</td><td>{item.zto}</td><td>{item.description}</td></tr>)}
                    </tbody>
                </Table>
            </Col>
        </Form.Row>
    )
};

export default SearchResultTable;
