import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';

import searchReducer from "../reducers/searchReducer";
import {supportModal} from '../reducers/supportReducer';
import {intl} from '../reducers/intlReducer'
import {reducer as form} from 'redux-form';
import {routerMiddleware} from 'react-router-redux';
import history from '../history.js';


const configureStore = () => {
    const rootReducer = combineReducers({
        intl,
        searchReducer,
        supportModal,
        form,
    });

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    // const store = createStore(rootReducer, applyMiddleware());


    // const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

    const store = createStore(rootReducer, compose(applyMiddleware(thunk, routerMiddleware(history))));

    return store;
};

export default configureStore;