import React, {Component} from 'react';
import styled from 'styled-components';
import renaultCar from '../../images/car.png';
import {FormattedMessage} from 'react-intl';
import mainPageMessages from '../../intl/home/mainPageMessages';
import {withRouter} from 'react-router-dom';

const Img = styled.img`
    height: 35%;
    width: 35%;
`;
Img.displayName = 'Img';

class HomePage extends Component {
    componentWillMount() {
        var _paq = window._paq = window._paq || [];
        _paq.push(['trackPageView']);
    }

    render() {
        return <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <h1 className="text-center"><FormattedMessage {...mainPageMessages.TITLE} /></h1>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="text-center">
                        <Img src={renaultCar}/>
                    </div>
                </div>
            </div>
        </div>
    }
}



export default withRouter(HomePage);