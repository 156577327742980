import {
    COMMON_INPUT_VALIDATION_REGEX,
    DATE_VALIDATION_REGEX,
    DIGIT_STRING_VALIDATION_REGEX,
    EMAIL_VALIDATION_REGEX,
    FILE_MAX_SIZE,
    IPN_VALIDATION_REGEX,
    NOT_SELECTED_OPTION
} from '../../constants/validationConstants';
import get from 'get-value';

export const required = (value, allValues) => {
    return (value || typeof value === 'number' || get(allValues, 'omitValidation', false) ? undefined : 'REQUIRED');
};

export const validEmail = value => {
    if (value && value.length > 0) {
        return EMAIL_VALIDATION_REGEX.test(value) ? undefined : 'INVALID_EMAIL';
    }
    return undefined;
};

export const selectRequired = value =>
    (!value || value === NOT_SELECTED_OPTION ? 'REQUIRED' : undefined);

export const positiveNumber = value => (value !== null && ((value && isNaN(Number(value))) || value < 1) ? 'POSITIVE_NUMBER' : undefined);

export const minLength = min =>
    value =>
        value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const validIPN = value =>
    (value && typeof value === 'string' && IPN_VALIDATION_REGEX.test(value)) ? undefined : 'INVALID_IPN';

export const validDate = value =>
    (value && !DATE_VALIDATION_REGEX.test(value)) ? 'INVALID_DATE' : undefined;

export const validDigitString = value =>
    (value && !DIGIT_STRING_VALIDATION_REGEX.test(value)) ? 'DIGITS_ONLY' : undefined;

export const validInput = (value = '') => ((!COMMON_INPUT_VALIDATION_REGEX.test(value)) && value.length !== 0) ? 'INVALID_INPUT' : undefined;

export const validTicketFiles = fileList => {
    if (fileList) {
        if (fileList.length > 20)
            return 'MAXIMUM_FILES';
        if (fileList.length !== 0) {
            for (let i = 0; i < fileList.length; i++) {
                if (fileList[i].size > FILE_MAX_SIZE) {
                    return 'MAXIMUM_FILE_SIZE';
                }
            }
        }
    }
};
