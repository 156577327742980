import React, {Component} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {Form, Col} from 'react-bootstrap';
import SearchResultTable from "./SearchResultTable";
import searchPageMessages from "../../../intl/search/searchLabels";
import {FormattedMessage} from "react-intl";

const StyledDiv = styled.div`
  padding-top: 10px;
  text-align: left;
  border-radius: 5px;
`;

class SearchResultSection extends Component {
    render() {
        return <>
        <StyledDiv className="border border-dark p-4 bg-light">
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <StyledDiv>
                    <Form.Group>
                        <Form.Row>
                            <Col xs={{span: 5, offset: 3}}>
                                <Form.Row>
                                    <Form.Label column sm="4"><b><FormattedMessage {...searchPageMessages.VIN} />: </b></Form.Label>
                                    <Col xs={{span: 6, offset: 2}}>
                                        <Form.Control value={this.props.vin} disabled/>
                                    </Col>
                                </Form.Row>
                            </Col>
                        </Form.Row>
                        <br/>

                        <Form.Row>
                            <Col xs={{span: 5, offset: 3}}>
                                <Form.Row>
                                    <Form.Label column sm="4"><b><FormattedMessage {...searchPageMessages.TVV} />: </b></Form.Label>
                                    <Col xs={{span: 6, offset: 2}}>
                                        <Form.Control value={this.props.tvv} disabled/>
                                    </Col>
                                </Form.Row>
                            </Col>
                        </Form.Row>
                        <br/>

                        <Form.Row>
                            <Col xs={{span: 5, offset: 3}}>
                                <Form.Row>
                                    <Form.Label column sm="4"><b><FormattedMessage {...searchPageMessages.COLOR} />: </b></Form.Label>
                                    <Col xs={{span: 6, offset: 2}}>
                                        <Form.Control value={this.props.color} disabled/>
                                    </Col>
                                </Form.Row>
                            </Col>
                        </Form.Row>
                        <br/>

                        <Form.Row>
                            <Col xs={{span: 5, offset: 3}}>
                                <Form.Row>
                                    <Form.Label column sm="4"><b><FormattedMessage {...searchPageMessages.MTW} />: </b></Form.Label>
                                    <Col xs={{span: 6, offset: 2}}>
                                        <Form.Control value={this.props.mtw} disabled/>
                                    </Col>
                                </Form.Row>
                            </Col>
                        </Form.Row>
                        <br/>

                        <Form.Row>
                            <Col xs={{span: 5, offset: 3}}>
                                <Form.Row>
                                    <Form.Label column sm="4"><b><FormattedMessage {...searchPageMessages.MWFA} />: </b></Form.Label>
                                    <Col xs={{span: 6, offset: 2}}>
                                        <Form.Control value={this.props.mwfa} disabled/>
                                    </Col>
                                </Form.Row>
                            </Col>
                        </Form.Row>
                        <br/>

                        <Form.Row>
                            <Col xs={{span: 5, offset: 3}}>
                                <Form.Row>
                                    <Form.Label column sm="4"><b><FormattedMessage {...searchPageMessages.MWBA} />: </b></Form.Label>
                                    <Col xs={{span: 6, offset: 2}}>
                                        <Form.Control value={this.props.mwba} disabled/>
                                    </Col>
                                </Form.Row>
                            </Col>
                        </Form.Row>
                        <br/>

                        <Form.Row>
                            <Col xs={{span: 5, offset: 3}}>
                                <Form.Row>
                                    <Form.Label column sm="4"><b><FormattedMessage {...searchPageMessages.MWAV} />: </b></Form.Label>
                                    <Col xs={{span: 6, offset: 2}}>
                                        <Form.Control value={this.props.mwav} disabled/>
                                    </Col>
                                </Form.Row>
                            </Col>
                        </Form.Row>
                        <br/>
                        <br/>
                        <SearchResultTable tableData={this.props.options}/>
                        <br/>


                    </Form.Group>
                </StyledDiv>
            </StyledDiv>
        </StyledDiv>
            <br/>
            </>
    }
};

const mapStateToProps = state => {
    return {
        vin: state.searchReducer.searchedVehicle.vin,
        tvv: state.searchReducer.searchedVehicle.tvv,
        color: state.searchReducer.searchedVehicle.color,
        mtw: state.searchReducer.searchedVehicle.mtw,
        mwav: state.searchReducer.searchedVehicle.mwav,
        mwfa: state.searchReducer.searchedVehicle.mwfa,
        mwba: state.searchReducer.searchedVehicle.mwba,
        options: state.searchReducer.searchedVehicle.options,
    }
};

export default connect(mapStateToProps)(SearchResultSection);