import { Log, UserManager } from 'oidc-client';
import { HOME_PATH } from '../routes/paths';
import makeRequest from '../api/rest';
import piwik from "../constants/piwikConfig";
import { OktaAuth } from '@okta/okta-auth-js';
import {config} from './config';
export const REFERRER_LOCAL_STORAGE = 'etc.referrer';
const oktaAuth = new OktaAuth({
    issuer: 'https://sso-uat.renault.com/oauth2/default',
    clientId: 'irn-58000_uat_code_vdvksouadqim',
    clientSecert: 'PE_pVJ8rx_UD1kIbhW-nVPqdyZY9D3KMlLW4tjGn3LKjaHNiKUAelQx0VhAPMIVJ',
    redirectUri: `${window.location.origin}/j_security_check`,
    scopes: ['openid', 'profile', 'email'],
    //pkce: false,
  });
  
export const authenticateAndRedirect = async () => {
    piwik.setUserId('pc00298');
    localStorage.setItem('referrer', window.location.pathname + window.location.search);
    await oktaAuth.signInWithRedirect();
};

export const processAuthenticationRedirect = async (code) => {
        
            const headers = {
              'Content-Type':'application/x-www-form-urlencoded',
              'accept': 'application/json'
            }
              const result = await makeRequest('GET',`${process.env.REACT_APP_BE_WS_URL}/auth?code=${code}`,null,headers)
              console.log('result',result)
           //const tokens = await oktaAuth.handleRedirectCallback();
    const lastVisitedPath = localStorage.getItem('referrer') || HOME_PATH;
    return { user: result, lastVisitedPath };
  };
  
  export const getAuthenticatedUser = async () => {
    try {
      const session = await oktaAuth.session.get();
      if (session && session.status === 'ACTIVE') {
        return session;
      } else {
        console.log('No active session found');
        return null;
      }
    } catch (error) {
      console.error('Error retrieving session:', error);
      return null;
    }
  };
