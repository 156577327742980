import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import {reduxForm} from 'redux-form';
import {PrimaryButton} from '../common/Button';
import {connect} from 'react-redux';
import {ModalFooter, ModalWindow} from '../common/modal/Modal';
import InputSelectFieldWithValidation from '../common/InputSelectFieldWithValidation';
import {languages} from '../../constants/Utils';
import localesMessages from '../../intl/common/localesMessages';
import supportMessages from "../../intl/layout/supportMessages";
import * as supportProperties from "../../constants/support";


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '30%',
        minWidth: '600px',
        maxHeight: '90%',
        overflowY: 'auto',
    },
    overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
};

const customHeaderStyles = {
    fontWeight: '700',
    textAlign: 'center',
    border: 0,
    padding: '15px',
};

const StyledForm = styled.form`
    padding-top: 5px;
    position: relative;
    margin: 0 auto;
`;

class SupportModal extends React.Component {
    close = () => {
        this.props.closeModal();
    };

    save = (data) => {
        this.props.saveModal(data);
    };


    render() {
        const {show, handleSubmit, pristine, submitting} = this.props;

        return (<>
                <ModalWindow isOpen={show}
                             onRequestClose={this.close}
                             customStyles={customStyles}>
                    <StyledForm onSubmit={handleSubmit(this.save)}>
                        <div style={customHeaderStyles}>
                            <FormattedMessage {...supportMessages.INFORMATION} />
                        </div>
                        <table className='table table-striped table-sm'>
                            <tbody>
                            <tr>
                                <td className='font-weight-bold'>
                                    <FormattedMessage {...supportMessages.VERSION} />
                                </td>
                                <td>{supportProperties.VERSION}</td>
                            </tr>
                            <tr>
                                <td className='font-weight-bold'>
                                    <FormattedMessage {...supportMessages.DATE_OF_COMMISIONING} />
                                </td>
                                <td>{supportProperties.DATE_OF_COMMISSIONING}</td>
                            </tr>
                            <tr>
                                <td className='font-weight-bold'>
                                    <FormattedMessage {...supportMessages.RESPONSIBLE} />
                                </td>
                                <td>{supportProperties.RESPONSIBLE}</td>
                            </tr>
                            <tr>
                                <td className='font-weight-bold'><FormattedMessage {...supportMessages.SUPPORT} />{':'}
                                </td>
                                <td>{supportProperties.SUPPORT}</td>

                            </tr>
                             <tr>
                                <td className='font-weight-bold'><FormattedMessage {...supportMessages.PODPORA} />{':'}
                                </td>
                                <td>{supportProperties.PODPORA}</td>

                            </tr>
                            </tbody>
                        </table>
                        <div style={customHeaderStyles}>
                            <FormattedMessage {...supportMessages.USER_COSTUMIZATION} />
                        </div>
                        <table className='table table-striped table-sm'>
                            <tbody>
                            <tr>
                                <td className='font-weight-bold'>
                                    <FormattedMessage {...supportMessages.LANGUAGE} />
                                </td>
                                <td>
                                    <InputSelectFieldWithValidation field='language'
                                                                    options={languages}
                                                                    noEmpty
                                                                    intlMessages={localesMessages}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <ModalFooter>
                            <div className="row">
                                <div className="col">
                                    <PrimaryButton type="submit" className="btn" disabled={pristine || submitting}>
                                        <FormattedMessage {...supportMessages.SAVE} />
                                    </PrimaryButton>
                                </div>
                            </div>
                        </ModalFooter>
                    </StyledForm>
                </ModalWindow>
            </>
        )
    }
}

const mapStateToProps = state => ({
    language: state.intl.language,
    locale: state.intl.locale,
    isLoading: state.supportModal.isLoading,
});


export default connect(mapStateToProps, {})(reduxForm({
    form: 'SupportModalForm',
    enableReinitialize: true,
})(SupportModal));
