import React, {Component} from 'react';
import {connect} from 'react-redux';
import SearchInputSection from './SearchInputSection';
import SearchResultSection from './SearchResultSection';

class SearchSection extends Component {
    render() {
        return (
            <>
                <SearchInputSection/>
                <br/>
                {this.props.status === 200 && this.props.vin ? <SearchResultSection/> : null}
            </>
        )
    }
};

const mapStateToProps = state => {
    return {
        vin: state.searchReducer.searchedVehicle.vin,
        status: state.searchReducer.searchedVehicle.status,
    }
};

export default connect(mapStateToProps)(SearchSection);