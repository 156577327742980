import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    INFORMATION: {
        id: 'support.information',
        defaultMessage: 'Information'
    },
    VERSION: {
        id: 'support.version',
        defaultMessage: 'Version'
    },
    DATE_OF_COMMISIONING: {
        id: 'support.dateOfCommissioning',
        defaultMessage: 'Date of Commissioning'
    },
    RESPONSIBLE: {
        id: 'support.responsible',
        defaultMessage: 'Responsible'
    },
    SUPPORT: {
        id: 'support.support1',
        defaultMessage: 'Support'
    },
    PODPORA: {
        id: 'support.podpora',
        defaultMessage: 'Podpora'
    },
    USER_COSTUMIZATION: {
        id: 'support.userCostumization',
        defaultMessage: 'User Costumization'
    },
    LANGUAGE: {
        id: 'support.language',
        defaultMessage: 'Language'
    },
    SAVE: {
        id: 'support.save',
        defaultMessage: 'Save'
    },
});

export default messages;
