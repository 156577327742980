import {defineMessages} from 'react-intl';

const messages = defineMessages({
    sk: {
        id: 'locale.sk',
        defaultMessage: 'Czech'
    },
    en: {
        id: 'locale.en',
        defaultMessage: 'English'
    },
});

export default messages;
