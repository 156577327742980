import React, {Component} from 'react';
import SearchSection from "./searchForm/SearchSection";
import {FormattedMessage} from "react-intl";
import searchPageMessages from "../../intl/search/searchLabels";
import {withRouter} from 'react-router-dom';

class SearchPage extends Component {
    componentWillMount() {
        var _paq = window._paq = window._paq || [];
        _paq.push(['trackPageView']);
    }

    render() {
        return ( <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <h1 className="text-center"><FormattedMessage {...searchPageMessages.TITLE} /></h1>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="text-center">
                        <SearchSection />
                    </div>
                </div>
            </div>
        </div>)
    }
}


export default withRouter(SearchPage);