import React, {Component, useState} from 'react';
import PropTypes from 'prop-types';
import {withRouter,useHistory} from 'react-router-dom';
import { OktaAuth,toRelativeUrl  } from '@okta/okta-auth-js';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import {connect} from 'react-redux';
// import makeRequest, {initAxios} from '../api/rest';
import AppContent from "./AppContent";
import HotSwappingIntlProvider from '../intl/ConnectedIntlProvider';
import {authenticateAndRedirect, getAuthenticatedUser, processAuthenticationRedirect} from '../utils/auth';
import piwik from "../constants/piwikConfig";
import {config,getOktaUrl} from '../utils/config';
const oktaAuth = new OktaAuth(config.oidc);
let code  = "";
const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(originalUri || '/');
  };
class App extends Component {
    componentDidMount() {
        const { history, location } = this.props;
      
        if (App.redirectedFromIdp(location)) {
          this.processAuthenticationRedirectResult(code);
          //this.processAuthenticatedUser(code).then((res)=>{console.log('token',res)}); 
          console.log(code)
        } else {
          let token = sessionStorage.getItem('idtoken') || null;
          if(!token){
            let url = getOktaUrl();

            window.location.href = url;
          }else{
            console.log(this.props.userDetail)
           // this.processAuthenticatedUser(authenticatedUser);
           // piwik.setUserId(authenticatedUser.claims.sub);
            piwik.push(['trackPageView']);
          }
          // oktaAuth.tokenManager.get('idToken').then(authenticatedUser => {
          //   if (!authenticatedUser || authenticatedUser.expiresAt < Date.now() / 1000) {
          //     //oktaAuth.signInWithRedirect();
          //     let url = getOktaUrl();

          //     window.location.href = url;
          //   } else if (!this.props.userDetail || Object.keys(this.props.userDetail).length === 0) {
          //     console.log(this.props.userDetail)
          //     this.processAuthenticatedUser(authenticatedUser);
          //     piwik.setUserId(authenticatedUser.claims.sub);
          //     piwik.push(['trackPageView']);
          //   }
          // });
        }
      }
    
      static redirectedFromIdp(location) {
        const urlParams = new URLSearchParams(location.search);
         code = urlParams.get('code');
        return code !== null;
      }
    
      processAuthenticationRedirectResult = async (code) => {
       // const token  = await processAuthenticationRedirect(code);
        sessionStorage.setItem('idtoken',code)
        //console.log('token 2',token);
        window.location.href = '/';
       };
    
      processAuthenticatedUser = user => {
        //initAxios(user);
      };
    
      render() {
        const { store } = this.props;
        return (
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <HotSwappingIntlProvider {...store}>
                <AppContent />
            </HotSwappingIntlProvider>
           </Security>
        );
    }
}

App.propTypes = {
  store: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  userDetail: PropTypes.object,
};

export default withRouter(App);