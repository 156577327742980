export const SUPPORT_MODAL_SHOW = 'SUPPORT_MODAL_SHOW';
export const SUPPORT_MODAL_CLOSE = 'SUPPORT_MODAL_CLOSE';
export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE';
export const USER_SETTINGS_SAVE = 'USER_SETTINGS_SAVE';

export const closeSupportModal = () => ({
    type: SUPPORT_MODAL_CLOSE,
    payload: {},
});


export const showSupportModal = () => (dispatch) => {
    dispatch({
        type: SUPPORT_MODAL_SHOW,
        payload: {},
    });
};

export const saveUserSettings = (language) => (dispatch) => {
    dispatch({
        type: LANGUAGE_CHANGE,
        payload: {
            language
        },
    });
};
