import { LANGUAGE_CHANGE } from '../actions/actionSupport';
import labels from '../labels';

const locale = (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage || navigator.browserLanguage;

// Split locales with a region code
const languageWithoutRegionCode = locale.toLowerCase().split(/[_-]+/)[0];

// Try full locale, fallback to locale without region code, fallback to en
const messages = labels[languageWithoutRegionCode] || labels[locale] || labels.en;


const initialState = {
    language: languageWithoutRegionCode,
    locale,
    messages,
};

export const intl = (state = initialState, action ) => {
    switch (action.type) {
        case LANGUAGE_CHANGE:
            switch (action.payload.language) {
                case 'en':
                    return { ...state, language: 'en', locale: 'en-GB', messages: labels['en']};
                case 'sk':
                    return { ...state, language: 'sk', locale: 'en-SK', messages: labels['sk']};
                default:
                    return { ...state};
            }
        default:
            return {...state};
    }
};

