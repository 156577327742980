import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'mainPage.title.welcome',
        defaultMessage: 'Welcome'
    },
});

export default messages;
