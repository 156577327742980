import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { NavLink, withRouter } from 'react-router-dom';
import { HOME_PATH, SEARCH_PATH } from '../../routes/paths';
import { PRIMARY_DARK_GREY, PRIMARY_YELLOW } from '../../theme/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faHome } from "@fortawesome/free-solid-svg-icons";
import { closeSupportModal, showSupportModal, saveUserSettings } from '../../actions/actionSupport';
import SupportModal from './SupportModal';
import searchPageMessages from "../../intl/search/searchLabels";
import {FormattedMessage} from "react-intl";


const TopNavigation = styled.nav`
    font-family: sans-serif;
    font-weight: bold;
    padding: 1px 10px;
    background-color: ${PRIMARY_DARK_GREY};
`;

const MenuLink = styled(NavLink)`
    :visited,
    :link {
        color: rgba(255, 255, 255, 0.85);
    }
    :active,
    :hover {
        background-color: ${PRIMARY_YELLOW};
        color: black;
    }
    transition: all 0.5s ease;

    && {
        padding: 0.6em 1em;
        margin: 5px;
    }

    &.${'nav-item-active'} {
        background-color: ${PRIMARY_YELLOW};
        color: black;
    }
`;

const SupportButton = styled.div`
    cursor: pointer;
    :visited {
        color: rgba(255, 255, 255, 0.85);
    }
    :active,
    :hover {
        background-color: ${PRIMARY_YELLOW};
        color: black;
    }
    transition: all 0.5s ease;

    && {
        margin: 5px;
        display: block;
        padding: .5rem;
        border-radius: .25rem;
    }
    color: white;
`;

function TopBar({isModalOpen, showSupportModal, closeSupportModal, language, saveUserSettings}) {

    return (
        <TopNavigation className="navbar navbar-expand-md nav-pills">
            <MenuLink exact className="nav-link" to={HOME_PATH} activeClassName="nav-item-active">
                <FontAwesomeIcon icon={faHome} size="lg"/>
            </MenuLink>


            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                        <MenuLink exact className="nav-link" to={SEARCH_PATH} activeClassName="nav-item-active">
                            <FormattedMessage {...searchPageMessages.TITLE} />
                        </MenuLink>
                    </li>
                </ul>
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <SupportButton onClick={showSupportModal}>
                            <FontAwesomeIcon icon={faCog} size="lg"/>
                        </SupportButton>
                    </li>
                </ul>
            </div>
            {isModalOpen && <SupportModal show={isModalOpen}
                                          closeModal={closeSupportModal}
                                          saveModal={(value) => {
                                              saveUserSettings(value.language)
                                          }}
                                          initialValues={{
                                              language: language.indexOf('-') >= 0 ? language.substring(0, language.indexOf('-')) : language
                                          }}
            />}
        </TopNavigation>
    );
}

const mapStateToProps = (state) => ({
    isModalOpen: state.supportModal.isModalOpen,
    language: state.intl.language,
});

export default withRouter(connect(mapStateToProps, {
    closeSupportModal,
    showSupportModal,
    saveUserSettings
})(TopBar));
