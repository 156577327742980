
export const config= {
    oidc: {
      clientId: 'irn-58000_uat_code_vdvksouadqim',
      CLIENT_SECRET:"PE_pVJ8rx_UD1kIbhW-nVPqdyZY9D3KMlLW4tjGn3LKjaHNiKUAelQx0VhAPMIVJ",
      url: 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/authorize',
      issuer: 'https://sso-uat.renault.com',
      redirectUri: window.location.origin + '/j_security_check',
      scopes: ['openid', 'profile', 'email'],
      //pkce: true,
      // httpRequestClient: {
      //   request: async (config) => {
      //     // Use your custom request function here
      //     return makeRequest(config.method, config.url, config.body, config.headers, config.proxyUrl);
      //   }
      // }
    },
    
  };


function createAuthorizeLocalUrl() {
  return 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/authorize?response_type=code&client_id=irn-58000_uat_code_vdvksouadqim&state=PE_pVJ8rx_UD1kIbhW-nVPqdyZY9D3KMlLW4tjGn3LKjaHNiKUAelQx0VhAPMIVJ&redirect_uri=https://etc.renault-edh-dev.com/j_security_check&scope=openid alliance_profile'
}
  function createAuthorizeDevUrl(){
    return 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/authorize?response_type=code&client_id=irn-58000_uat_code_vdvksouadqim&state=PE_pVJ8rx_UD1kIbhW-nVPqdyZY9D3KMlLW4tjGn3LKjaHNiKUAelQx0VhAPMIVJ&redirect_uri=https://etc.renault-edh-dev.com/j_security_check&scope=openid alliance_profile'
  }
  function createAuthorizeIntUrl(){
return 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/authorize?response_type=code&client_id=irn-58000_uat_code_vdvksouadqim&state=PE_pVJ8rx_UD1kIbhW-nVPqdyZY9D3KMlLW4tjGn3LKjaHNiKUAelQx0VhAPMIVJ&redirect_uri=https://etc.renault-edh-int.com/j_security_check&scope=openid alliance_profile'
  }
function createAuthorizeOpeUrl() {
  return 'https://sso.renault.com/oauth2/aus133y6mks4ptDss417/v1/authorize?response_type=code&client_id=irn-58000_ope_code_ejriztmuqb1v&state=uBSXxnwILHUjz8DIwwQ_btWd5C8Vuo8SUIeVLrmZ4X67INphrh5NGl_p4iuURV8-&redirect_uri=https://etc.renault-edh.com/j_security_check&scope=openid alliance_profile'
}
  export const getOktaUrl=() =>{
  let url = "";
    const environementName = process.env.REACT_APP_ENV;
    switch (environementName) {
      case "local":
        url = createAuthorizeLocalUrl();
        break;
      case "dev":
        url = createAuthorizeDevUrl();
        break;
      case "int":
        url = createAuthorizeIntUrl();
        break;
      case "ope":
        url = createAuthorizeOpeUrl();
        break;
    }
    return url;
    // return 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/authorize?response_type=code&client_id=irn-58000_uat_code_vdvksouadqim&state=PE_pVJ8rx_UD1kIbhW-nVPqdyZY9D3KMlLW4tjGn3LKjaHNiKUAelQx0VhAPMIVJ&redirect_uri=https://etc.renault-edh-int.com/j_security_check&scope=openid alliance_profile'
  }
  

  