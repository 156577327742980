import React, {Component} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import * as actionSearch from '../../../actions/search/actionSearch'
import {Form, Button, Col} from 'react-bootstrap'
import searchPageMessages from "../../../intl/search/searchLabels";
import {FormattedMessage} from "react-intl";
import Spinner from 'react-bootstrap/Spinner'


const StyledDiv = styled.div`
  padding-top: 10px;
  border-radius: 5px;
`;

class SearchInputSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchInput: this.props.vin,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    render() {

        return <>
            <StyledDiv className="border border-dark p-4 bg-light">
                <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                    <StyledDiv>
                        <Form.Group>
                            <Form.Row>
                                <Col xs={{span: 5, offset: 3}}>
                                    <Form.Control placeholder='VIN' name='searchInput' maxLength={17}
                                                  isInvalid={this.props.status !== "" && this.props.status !== 200}
                                                  value={this.state.searchInput} disabled={this.props.isLoading} onChange={this.handleChange}/>
                                    <Form.Control.Feedback type="invalid">
                                        {this.props.status !== "" && this.props.status === 404 &&
                                        <FormattedMessage {...searchPageMessages.VIN_NOT_FOUND} />
                                        }
                                        {this.props.status !== "" && this.props.status >= 500 && this.props.status < 600 &&
                                        <FormattedMessage {...searchPageMessages.BVM_API_ERROR} />
                                        }
                                        {this.props.status !== "" && this.props.status === 999 &&
                                        <FormattedMessage {...searchPageMessages.FAILED_TO_FETCH_BE} />
                                        }
                                    </Form.Control.Feedback>
                                </Col>
                                <Col xs={{span: 1}}>
                                    <Button variant="warning"
                                            onClick={() => (this.props.onLoading(), this.props.onSearch(this.state.searchInput))}
                                            disabled={!this.state.searchInput || this.state.searchInput.length !== 17 || (this.props.isLoading)}>
                                        {this.props.isLoading ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/><FormattedMessage {...searchPageMessages.LOADING} /></> : <FormattedMessage {...searchPageMessages.TITLE} />}
                                    </Button>
                                </Col>
                            </Form.Row>
                        </Form.Group>
                    </StyledDiv>
                </StyledDiv>
            </StyledDiv>
        </>
    }
};

const mapStateToProps = state => {
    return {
        vin: state.searchReducer.searchedVehicle.vin,
        tvv: state.searchReducer.searchedVehicle.tvv,
        color: state.searchReducer.searchedVehicle.color,
        mtw: state.searchReducer.searchedVehicle.mtw,
        mwav: state.searchReducer.searchedVehicle.mwav,
        mwfa: state.searchReducer.searchedVehicle.mwfa,
        mwba: state.searchReducer.searchedVehicle.mwba,
        aoczto: state.searchReducer.searchedVehicle.aoczto,
        status: state.searchReducer.searchedVehicle.status,
        isLoading: state.searchReducer.isLoading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSearch: (vin) => dispatch(actionSearch.searchVin(vin)),
        onLoading: () => dispatch(actionSearch.loading()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchInputSection);