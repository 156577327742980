import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

import history from '../history.js';
import piwik from '../constants/piwikConfig';


const Root = ({ store }) => (
    <Provider store={store}>
        <Router history={piwik.connectToHistory(history)}>
            <App store={store}/>
        </Router>
    </Provider>
);

export default Root;
